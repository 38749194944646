import { Component } from 'react';
import { connect } from 'react-redux';
import {
  validateEmail,
  validatePhone,
  signUpV2,
} from 'tg-core-redux/lib/modules/player/action';
import getByPath from 'lodash/get';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { withCookies } from 'react-cookie';
import { withConfig } from '@config';
import { hideToast } from '@actions/toast';
import { createAlertId, getKey } from '@components/Alert';
import { loadScript } from '@utils/script';
import { fetchContent, filterOutContentBySegmentation } from '@actions/content';
import { closeHash } from '@utils/hash';
import { selectTac } from '@utils/tac';
import translate from 'tg-core-components/lib/lib/utils/translate';

@withCookies
@withConfig
@injectIntl
@withRouter
@connect(
  state => ({
    jurisdiction: state.app.jurisdiction,
    device: state.app.device,
    browser: state.app.browser,
    operatingSystem: state.app.operatingSystem,
    locale: state.app.locale,
    content: state.content,
    tac: selectTac(state),
  }),
  {
    validateEmail,
    validatePhone,
    signUpV2,
    hideToast,
    filterOutContentBySegmentation,
    fetchPromotions: locale => fetchContent('promotion', locale),
  }
)
class Regily extends Component {
  componentDidMount = async () => {
    const { locale, config } = this.props;
    this.setLanguage(locale);
    this.setBonuses(locale);
    await this.load(getByPath(config, 'regily.host'));
    if (this.shouldOpen()) this.open();
  };

  componentDidUpdate = prevProps => {
    const { locale, config } = this.props;
    if (locale !== prevProps.locale) {
      this.setLanguage(locale);
      this.setBonuses(locale);
      this.load(getByPath(config, 'regily.host'));
    }
    if (this.shouldOpen()) this.open();
  };

  shouldOpen = () =>
    __CLIENT__ && getByPath(this, 'props.config.regily.host') && window.regily;

  open = () => window.regily.signUp.open();

  close = () => {
    if (__CLIENT__) window.location.hash = '';
  };

  setLanguage = locale => {
    const language = locale.substring(0, 2);
    document.documentElement.dataset.regilyLanguage =
      language === 'nb' ? 'no' : language;
  };

  setBonuses = async locale => {
    const { fetchPromotions, filterOutContentBySegmentation } = this.props;

    await fetchPromotions(locale);
    filterOutContentBySegmentation();

    const {
      intl,
      content: {
        promotions: { data: promotions },
      },
    } = this.props;

    const bonusList = (promotions || [])
      .filter(p => p.bonusCode && p.bonusType === 'signup')
      .map(promotion => ({
        id: promotion.bonusCode,
        title: promotion.title,
        description: promotion.disclaimer,
        imageLink: `https:${getByPath(promotion, 'image.file.url')}`,
      }));

    const welcomeBonusesSettings = {
      bonusConfig: {
        allowSkippingBonuses: true,
        title: translate(
          {
            id: 'label.regily.title',
            defaultMessage: 'Get your welcome bonus',
          },
          intl
        ),
        description: translate(
          {
            id: 'label.regily.title-description',
            defaultMessage: 'Your choice',
          },
          intl
        ),
      },
      bonusList,
    };

    if (bonusList?.length) {
      document.documentElement.dataset.regilyBonuses = 'true';
      document.documentElement.dataset.regilyCustomData = JSON.stringify(
        welcomeBonusesSettings
      );
    }
  };

  load = host => {
    this.regilyDoneCallback();
    this.regilyUpdateCallback();
    this.regilyEventCallback();
    return typeof host === 'string'
      ? loadScript(host, 'regilyScript')
      : Promise.resolve();
  };

  regilyDoneCallback = () => {
    if (!__CLIENT__ || window.regilyDoneCallback) return;
    Object.defineProperty(window, 'regilyDoneCallback', {
      value: regilyDoneCallbackData => {
        const formattedData = this.mapRegilyData(regilyDoneCallbackData);
        return this.saveDataFromRegily(formattedData);
      },
    });
  };

  regilyUpdateCallback = () => {
    if (!__CLIENT__ || window.regilyUpdateCallback) return;
    Object.defineProperty(window, 'regilyUpdateCallback', {
      value: (data, dataDiff) => this.validateFields(data, dataDiff),
    });
  };

  regilyEventCallback = () => {
    const { history, location } = this.props;

    if (!__CLIENT__ || window.regilyEventCallback) return;
    Object.defineProperty(window, 'regilyEventCallback', {
      value: data => {
        if (data.action === 'close-module') {
          if (data.flowStatus.complete) {
            history.push({
              hash: '#dashboard/activate',
            });
          } else {
            history.push({
              hash: closeHash(location.hash, '#sign-up'),
            });
          }
        }
      },
    });
  };

  createError = (
    errorType = 'duplicateAccount',
    errorMessage = 'Account already exists'
  ) => {
    var error = new Error(errorMessage);
    error.name = errorType;
    return error;
  };

  saveDataFromRegily = formData => {
    const {
      intl,
      signUpV2,
      jurisdiction,
      config,
      device,
      browser,
      operatingSystem,
      experimentWin,
    } = this.props;

    formData.DontSendActivationCode = false;
    formData.Platform = device;
    formData.Browser = browser;
    formData.OperatingSystem = operatingSystem;

    return new Promise((resolve, reject) => {
      signUpV2(formData).then(action => {
        if (
          (action.type && action.type.includes('_FAILURE')) ||
          getByPath(action, 'data.Issues', []).length > 0
        ) {
          const alertId = createAlertId(action);
          const translationKey = getKey(intl, alertId);

          // Regily error format
          const error = new Error(
            translate(
              {
                id: translationKey,
                defaultMessage:
                  'Something went wrong. Please try again or contact customer support.',
              },
              intl
            )
          );

          error.name = 'customError';

          error.title = translate(
            {
              id: 'label.registration.error',
              defaultMessage:
                'There was an error when trying to create your account...',
            },
            intl
          );

          error.action = 'close';

          return reject(error);
        }

        const responseData = {
          welcome: {
            title: translate(
              {
                id: 'label.welcome',
                defaultMessage: 'Welcome',
              },
              intl
            ),
            body: translate(
              {
                id: 'label.registration.success',
                defaultMessage: 'Registration successful',
              },
              intl
            ),
            button: !['sga', 'dga', 'curacao'].includes(
              jurisdiction.toLowerCase()
            )
              ? translate(
                  {
                    id: 'action.button.activate_account',
                    defaultMessage: 'Activate account',
                  },
                  intl
                )
              : translate(
                  {
                    id: 'action.deposit',
                    defaultMessage: 'Deposit',
                  },
                  intl
                ),
            url:
              !config?.disableActivation &&
              !['sga', 'dga', 'curacao'].includes(jurisdiction.toLowerCase())
                ? '/dashboard/activate'
                : '/dashboard/deposit',
          },
        };

        experimentWin();
        resolve(responseData);
      });
    });
  };

  playerExists = res => {
    const code = getByPath(res, [
      'payload',
      'value',
      'response',
      'data',
      'Code',
    ]);

    if (!code || code === 'RateLimited') return false;
    return code;
  };

  validateFields = (data, dataDiff) => {
    const {
      validateEmail,
      validatePhone,
      hideToast,
      jurisdiction,
    } = this.props;
    return new Promise((resolve, reject) => {
      let validationDone = false;

      // Validate email
      if (
        getByPath(dataDiff, ['credentials', 'email'], false) &&
        getByPath(data, ['credentials', 'email'], false)
      ) {
        validationDone = true;
        validateEmail(
          encodeURIComponent(data.credentials.email),
          jurisdiction
        ).then(res => {
          if (res.type === 'VALIDATE_EMAIL_FAILURE') {
            hideToast();
          }

          this.playerExists(res) ? reject(this.createError()) : resolve();
        });
      }

      // Validate phone
      if (
        getByPath(dataDiff, ['user', 'phoneE164'], false) &&
        getByPath(data, ['user', 'phoneE164'], false) &&
        data.user.phoneE164.length > 8
      ) {
        validationDone = true;
        validatePhone(data.user.phoneE164.replace('+', '00')).then(res => {
          if (res.type === 'VALIDATE_PHONE_FAILURE') {
            hideToast();
          }

          this.playerExists(res) ? reject(this.createError()) : resolve();
        });
      }

      if (!validationDone) resolve();
    });
  };

  mapRegilyData = data => {
    const { jurisdiction, locale, device, cookies, config, tac } = this.props;
    const { user, credentials, consent, mapped } = data;

    const OptIns = consent?.alternative_communications?.consent
      ? ['NewsEmails', 'BonusEmails', 'NewsSMSs', 'BonusSMSs', 'Phone']
      : [];

    const Limits =
      mapped && mapped.Limits
        ? mapped.Limits.map(limit => ({
            Type: limit.Type,
            Timespan: limit.Timespan,
            Amount: limit.Amount,
          }))
        : [];

    cookies.set('bonusCodeDeposit', user.selectedBonus || user.bonus, {
      domain: config.cookieDomain(),
      expires: new Date(Date.now() + 31556952000),
      secure: config.CURRENT_ENV === 'prod',
    });

    return {
      Password: credentials.password,
      Email: credentials.email,
      BirthDate: user.birthdate,
      FirstName: user.firstName,
      LastName: user.lastName,
      Address1: user.streetName + ' ' + user.houseNumber,
      City: user.city,
      Zip: user.postalCode,
      Country: user.country,
      MobilePhoneNumber: user.phoneE164,
      Language: locale.substring(0, 2),
      Gender: user.gender,
      Currency: user.currency,
      AffiliateTag: cookies.get(config.btagCookie),
      Platform: device,
      Jurisdiction: jurisdiction,
      TacVersionAccepted: tac.version,
      OptIns,
      Limits,
    };
  };

  render() {
    // All rendering is handled by third party script
    return null;
  }
}

export default Regily;
