import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { loadContents } from '@actions/content';
import Popup from '@components/Popup';
import { getConfig } from '@config';
import withRouteData from '@utils/withRouteData';
import { withOverlayRouter } from '@components/OverlayRouter';
import { createAlertId, getKey } from '@components/Alert';
import Alerts from '@components/Alerts';
import { removeAlerts } from '@actions/alert';
import { pushGtmEvent } from '@actions/app';
import { withExperiment } from '@components/hoc/withExperiment';
import Regily from '@components/Regily';
import Footer, { selectFooterProps } from '@components/Footer';
import defaultBackground from '../../images/sign-up-banner.png';

import {
  getSocialSecurityNumber,
  getStorageParsedData,
  updateSignUpData,
} from '@utils/localstorage';
import compose from 'recompose/compose';
import { getSteps, getStepStatuses, createSectionId } from './helpers';
import Layout from './Layout';
import {
  Email,
  EmailSE,
  Error,
  Issues,
  Close,
  Password,
  PasswordES,
  Phone,
  BirthDate,
  Location,
  Name,
  NameES,
  Blocked,
  Exists,
} from './steps';
import { closeHash } from '@utils/hash';
import { GTM_EVENTS } from '@utils/google-tag-manager';

const SignUpContainer = ({
  isRouteDataLoading,
  overlayLocation,
  overlayMatch,
  history,
  location,
  experimentPlay,
  experimentVariant,
  experimentWin,
}) => {
  const contentfulConfig = useSelector(state => state.content.config.data);
  const sets = useSelector(state => state.content.sets.data);
  const footerProps = useSelector(state => selectFooterProps(state));
  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const config = useSelector(state => getConfig(state.app.jurisdiction));
  const ipCountry = useSelector(state => state.app.ipCountry);
  const locale = useSelector(state => state.app.locale);
  const device = useSelector(state => state.app.device);
  const browser = useSelector(state => state.app.browser);
  const operatingSystem = useSelector(state => state.app.operatingSystem);
  const jwtToken = useSelector(state => state.player.jwtToken);
  const alerts = useSelector(state =>
    state.alerts.data.filter(a =>
      ['ACTIVATE', 'REQUEST_ACTIVATION_CODE'].includes(a.type)
    )
  );

  const signUpItems = sets?.find(s => s.identifier === 'sign-up-items');
  const allCountries = contentfulConfig?.find(c => c.key === 'countries');

  // Remove countries that should not allow registration
  const countries = {
    value: allCountries?.value?.filter(country => !country.disableRegistration),
  };

  const country =
    countries?.value?.length === 1
      ? countries?.value?.[0]
      : countries?.value?.find(c => c.value === ipCountry) || {};

  const currencies = contentfulConfig?.find(c => c.key === 'currency')?.value;

  const hideCurrencyStep = currencies?.length === 1;
  const overrideCountry = config?.overrideSignUpCountryMap?.[ipCountry];
  const steps = getSteps(
    jurisdiction,
    jwtToken,
    config,
    overrideCountry,
    hideCurrencyStep
  );

  const layoutRef = useRef();
  const [cookies] = useCookies([config.btagCookie]);
  const [previousStep, setPreviousStep] = useState(null);
  const [step, setStep] = useState(steps[0]);
  const [stepIdentifier, setStepIdentifier] = useState(step.name);
  const [nextStepToFill, setNextStepToFill] = useState(steps[0]);
  const [fields, setFields] = useState({
    Limits: [],
    MobilePhoneNumber: country.callingCode && `00${country.callingCode}`,
    Country: country.value,
    Currency: hideCurrencyStep ? currencies[0]?.value : country.currency,
    Language: locale.includes('-') ? locale.split('-')[0] : locale,
    PersonalId: getSocialSecurityNumber() || undefined,
    Jurisdiction: jurisdiction,
    Platform: device,
    OperatingSystem: operatingSystem,
    Browser: browser,
    ...getStorageParsedData('signUpData'),
  });

  const [errors, setErrors] = useState({});
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [skipValidation, setSkipValidation] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFieldChanges = async fields => {
    const validate = step.validate ? step.validate : () => Promise.resolve();
    const stepFields = step.fields || [];

    let stepStatuses = getStepStatuses(stepFields, fields, errors);

    // Fix issue where birth date is validated before completely filled.
    if (step.name === 'birth-date' && fields.BirthDate?.length < 9) return;

    // We do not want to validate directly when we are sent back after sign up error.
    if (skipValidation) {
      setStatuses(stepStatuses);
      setSkipValidation(false);

      return;
    }

    setIsLoading(true);

    const result = await validate(fields, jurisdiction);

    if (!result) return;

    stepStatuses = getStepStatuses(stepFields, fields, result.errors);

    setIsLoading(false);
    setErrors(result.errors);
    setStatuses(stepStatuses);
  };

  const handleFieldChangesDebounced = useCallback(
    debounce(handleFieldChanges, 500),
    [step, skipValidation]
  );

  const shouldRunExperiment = config.experiments?.find(
    e => e.name === 'sign-up-no-index'
  );

  // Handle sign up init and close GTM events.
  useEffect(() => {
    dispatch(pushGtmEvent({ event: GTM_EVENTS.SIGNUP_INIT }));

    return () => dispatch(pushGtmEvent({ event: GTM_EVENTS.SIGNUP_CLOSE }));
  }, [dispatch]);

  // Validate fields as user types.
  useEffect(() => {
    handleFieldChangesDebounced(fields);
  }, [fields]); // eslint-disable-line

  // Update local storage.
  useEffect(() => {
    updateSignUpData(fields);
  }, [fields]);

  // Push GTM event on step change, reset validation state, scroll to top and set step identifier.
  useEffect(() => {
    setStepIdentifier(step.name);

    dispatch(pushGtmEvent({ event: `sign up ${step.name}` }));

    if (layoutRef.current) layoutRef.current.scroll({ top: 0 });

    handleFieldChangesDebounced(fields);
  }, [step, dispatch]); // eslint-disable-line

  const nextStep = async () => {
    const currentStepIndex = steps.indexOf(step);
    const nextStepToFillIndex = steps.indexOf(nextStepToFill);

    if (nextStepToFillIndex < currentStepIndex + 1) {
      setNextStepToFill(steps[currentStepIndex + 1]);
    }

    setStep(steps[currentStepIndex + 1]);
  };

  const goBack = () => {
    setStep(steps[steps.indexOf(step) - 1]);
  };

  const onClose = () => {
    if (step.name === 'close') return;

    setPreviousStep(step);
    setStep(Close);
  };

  const signIn = data => {
    setIsLoading(true);

    if (data.query?.code) {
      data.Code = data.query.code;
      data.State = data.query.state;
      delete data.query;
    }

    const promise = data.Code
      ? dispatch(playerActions.signInWithCode(data))
      : dispatch(playerActions.signInV2(data));

    return promise.then(handleResponse);
  };

  const signUp = data => {
    const AffiliateTag = cookies[config.btagCookie];
    const termsAndConditions = sets?.find(
      s => s.identifier === 'terms-conditions'
    );
    const TacVersionAccepted = termsAndConditions?.config?.version;
    const Address1 = `${fields.Address} ${fields.HouseNumber}`;
    const PersonIdSupportNumber = fields.SupportNumber;

    const DontSendActivationCode = false;

    const formData = {
      ...fields,
      Address1,
      AffiliateTag,
      TacVersionAccepted,
      DontSendActivationCode,
      PersonIdSupportNumber,
    };

    // Reformat birth date after .net-8 migration.
    formData.BirthDate = formData.BirthDate.replaceAll('/', '-');

    // Remove, since this was just temporary.
    delete formData.HouseNumber;
    delete formData.AcceptTerms;
    delete formData.SupportNumber;

    if (jurisdiction === 'curacao' && config?.quickRegistration) {
      delete formData.Country;
      delete formData.Address1;
      delete formData.Limits;
      delete formData.MobilePhoneNumber;
    }

    formData.OptIns = fields.OptInMarketing
      ? ['NewsEmails', 'BonusEmails', 'NewsSMSs', 'BonusSMSs', 'Phone']
      : [];

    if (['ce', 'nz'].includes(formData.Language)) formData.Language = 'en';
    else if (formData.Language === 'nb') formData.Language = 'no';

    if (jurisdiction === 'sga') {
      formData.Country = 'SE';
      formData.Currency = 'SEK';
      formData.Language = 'sv';
    }

    if (overrideCountry) {
      formData.Country = overrideCountry;
    }

    if (jwtToken) {
      formData.JwtTokenString = jwtToken;
    }

    if (config.autoSetCurrency) {
      formData.Currency = config.autoSetCurrency;
    }

    setIsLoading(true);

    const promise = jwtToken
      ? dispatch(playerActions.signUpWithToken(formData))
      : data?.Code
      ? dispatch(playerActions.signUpWithCode(formData))
      : dispatch(playerActions.signUpV2(formData));

    return promise.then(handleResponse);
  };

  const handleResponse = action => {
    setIsLoading(false);

    if (action?.type?.includes('_FAILURE')) {
      const alertId = createAlertId(action);
      const translationKey = getKey(intl, alertId);
      const error = action?.payload?.value?.response?.data;

      const sectionId = createSectionId(error);
      const errorContent = signUpItems?.items?.find(
        c => c.identifier === sectionId
      )?.content;

      setSkipValidation(true);

      if (
        [
          'PlayerBlocked',
          'GamstopValidationError',
          'RofusValidationError',
          'SpelpauseValidationError',
          'SpanishGamblingAuthorityValidationError',
          'CountryNotAllowed',
          'RegionBlocked',
          'MissingNameOrAddress',
        ].includes(error?.Code)
      ) {
        setErrors({ Blocked: errorContent });
        return setStep(Blocked);
      }

      if (['PlayerExists', 'PhoneNumberExists'].includes(error?.Code)) {
        setErrors({ Exists: translationKey });
        return setStep(Exists);
      }

      if (error?.Code === 'EmailDomainBlocked') {
        setErrors({ Email: translationKey });
        return jurisdiction === 'sga' ? setStep(EmailSE) : setStep(Email);
      }

      if (error?.Details?.['request.BirthDate']?.[0]) {
        setErrors({ BirthDate: translationKey });
        return setStep(BirthDate);
      }

      if (error?.Details?.['request.Password']?.[0]) {
        setErrors({ Password: translationKey });
        return jurisdiction === 'es' ? setStep(PasswordES) : setStep(Password);
      }

      if (error?.Details?.['request.MobilePhoneNumber']?.[0]) {
        setErrors({ MobilePhoneNumber: translationKey });
        return setStep(Phone);
      }

      if (error?.Details?.['request.Address']?.[0]) {
        setErrors({ Address: translationKey });
        return setStep(Location);
      }

      if (error?.Details?.['request.FirstName']?.[0]) {
        setErrors({ FirstName: translationKey });
        return jurisdiction === 'es' ? setStep(NameES) : setStep(Name);
      }

      if (error?.Details?.['request.LastName']?.[0]) {
        setErrors({ LastName: translationKey });
        return jurisdiction === 'es' ? setStep(NameES) : setStep(Name);
      }

      if (error?.Details?.['request.LastName2']?.[0]) {
        setErrors({ LastName2: translationKey });
        return jurisdiction === 'es' ? setStep(NameES) : setStep(Name);
      }

      setErrors({
        Error: errorContent,
      });

      setStep(Error);

      // SGA Sign up.
    } else if (action?.data?.JwtTokenSignup) {
      nextStep();
    } else if (action?.data?.Issues?.length > 0) {
      setErrors({ Issues: action.data.Issues });
      setStep(Issues);
      // Successful sign in/sign up.
    } else {
      if (shouldRunExperiment) {
        experimentWin();
      }

      localStorage.removeItem('signUpData');

      if (jurisdiction === 'sga') {
        history.push({
          hash: closeHash(overlayLocation.hash, overlayMatch.url),
        });
      } else {
        nextStep();
      }
    }

    return action;
  };

  const closeHashRoute = () => {
    return history.replace({
      hash: closeHash(overlayLocation.hash, overlayMatch.url),
    });
  };

  const onChange = field => {
    setFields({
      ...fields,
      ...field,
    });
  };

  const Step = step.Component;

  const selector = useSelector(state =>
    step.selector ? step.selector(state) : {}
  );

  const backgroundImage =
    signUpItems?.items?.find(item => item.identifier.includes('background'))
      ?.backgroundImage?.file?.url || defaultBackground;

  const content = signUpItems?.items?.filter(item =>
    item.identifier.includes(stepIdentifier)
  );

  // Get unique icons.
  const icons = [...new Set(steps.map(step => step.icon))].filter(Boolean);

  if (shouldRunExperiment && experimentVariant === 'regily')
    return <Regily experimentWin={experimentWin} />;

  return (
    <Popup className="SignUpContainer v2" showLoading={isRouteDataLoading}>
      <Layout
        stepIndex={steps.indexOf(step)}
        onChange={onChange}
        fields={fields}
        setRef={ref => (layoutRef.current = ref)}
        showBack={step.name !== steps[0].name}
        onBack={goBack}
        onClose={onClose}
        closeHashRoute={closeHashRoute}
        onIconClick={icon => setStep(steps.find(s => s.icon === icon))}
        content={content}
        backgroundImage={backgroundImage}
        icons={icons}
        config={config}
        currentStep={step}
        nextStepToFill={nextStepToFill}
        stepIdentifier={stepIdentifier}>
        <Alerts
          scrollIntoView={false}
          alerts={alerts}
          removeAlerts={type => dispatch(removeAlerts(type))}
        />

        <Step
          {...selector}
          location={location}
          setIsLoading={setIsLoading}
          errors={errors}
          statuses={statuses}
          isLoading={isLoading}
          intl={intl}
          fields={fields}
          nextStep={nextStep}
          signIn={signIn}
          signUp={signUp}
          onExists={() => setStep(Exists)}
          onBack={() => setStep(previousStep)}
          onClose={closeHashRoute}
          onChange={onChange}
          setStepIdentifier={setStepIdentifier}
          countries={countries} // Temporarily override selectors
        />
      </Layout>
      <Footer {...footerProps} />
    </Popup>
  );
};

SignUpContainer.hashRoute = true;
SignUpContainer.globalLoader = false;
SignUpContainer.dataLoader = loadContents([
  {
    name: 'set',
    identifiers: ['sign-up-items'],
  },
  { name: 'bonus' },
  { name: 'promotion' },
  { name: 'payment' },
]);

export default compose(
  withRouter,
  withRouteData,
  withOverlayRouter,
  withExperiment('sign-up-no-index')
)(SignUpContainer);
